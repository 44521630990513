import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonResponse } from 'app/server/models';
import { Azienda, AziendaUtente } from 'app/server/models/azienda/azienda';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class AziendaService {

	currentAzienda: BehaviorSubject<AziendaUtente>;
	currentAziendaId: BehaviorSubject<string>;
	constructor(private _http: HttpClient) {
		this.currentAzienda = new BehaviorSubject<AziendaUtente>(null);
		this.currentAziendaId = new BehaviorSubject<string>(null);

		if (this.getAziendaFromStorage()) {
			this.currentAziendaId.next(String(this.getAziendaFromStorage()));
		}
	}

	getAzienda(): Observable<Azienda> {
		return this._http.get<Azienda>(`/azienda`);
	}

	getAziendeUtente(): Observable<Array<AziendaUtente>> {
		return this._http.get<Array<AziendaUtente>>(`/aziende`)
	}

	setAzienda(azienda?: AziendaUtente) {

		if (azienda) {
			sessionStorage.setItem('azienda', String(azienda.xazi));
			this.currentAzienda.next(azienda);
			this.currentAziendaId.next(azienda.xazi);
		} else {
			sessionStorage.removeItem('azienda');
			this.currentAzienda.next(null);
			this.currentAziendaId.next(null);
		}
	}

	getAziendaFromStorage(): string {
		if (sessionStorage.getItem('azienda') !== null) {
			return sessionStorage.getItem('azienda');
		} else {
			return undefined;
		}
	}
}
