import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AziendaService } from '../services/azienda/azienda.service';

@Injectable()
export class AziendaInterceptor implements HttpInterceptor {
  constructor(
    private _aziendaService: AziendaService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    if (this._aziendaService.currentAziendaId.value) {
      request = request.clone({
        setHeaders: {
          'x-azi': this._aziendaService.currentAziendaId.value,
        },
      });
    }

    return next.handle(request);
  }
}
